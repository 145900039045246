footer.section {
  padding: 0;
  margin: 0;
}

.row {
  display: flex;
}
.player {
  height: 650px;
  width: 650px;
}

.mx {
  margin-left: 5px;
  margin-right: 5px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.loading-icon {
  border-color: transparent;
}

.page-body {
  min-height: calc(100vh - 120px);
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 150px);
}

.table td,
.table th {
  padding: 5px;
}

.banner-logo {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .section {
    padding: 1.5rem 0.75rem;
  }
  html {
    font-size: 14px;
  }
  footer.section {
    padding: 1rem;
  }
  .page-body {
    min-height: calc(100vh - 150px);
  }
  .hero.is-fullheight-with-navbar {
    min-height: calc(100vh - 200px);
  }
  .table td,
  .table th {
    padding: 0.5em 0.75em;
  }
  .banner-logo {
    width: 125px;
  }
  footer.section .column {
    padding: 0.25rem 1rem;
  }
  .paginationBottom {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    position: fixed;
    left: 0;
    right: 0;
    bottom: 180px;
    max-width: 100%;
    margin: auto;
    padding: 5px;
    text-align: center;
    /* display: block !important; */
  }

  .totalResultLabel {
    width: 100%;
  }
}

.image.is-150x150 {
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.image.is-250x250 {
  width: 250px;
  height: 250px;
  overflow: hidden;
}

.container.compact {
  max-width: 400px !important;
}

.container.medium {
  max-width: 700px;
}

.personal-details {
  padding: 1rem;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.date-input {
  width: 100% !important;
}

.banner-contents {
  max-width: 375px;
}

.banner-text {
  margin-bottom: 1rem;
  list-style-type: none;
  /* padding-left: 0px; */
  flex-direction: column;
  flex-grow: 0.2 !important;

  position: relative;
}

.margin-right {
  margin-right: 0.5rem;
}

.margin-bottom {
  margin-bottom: 0.5rem;
}

.spacer {
  padding-bottom: 1.5rem;
}

.reset-password {
  float: right;
}

/* timestamps profile body */
.timestamp {
  text-align: right !important;
  display: flex;
  flex-direction: column;
}

/* flags sign up body */
.flag-select {
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
  background-color: white;
  border-color: #dbdbdb !important;
  border-radius: 4px;
  color: #363636;
  border: 1px solid transparent;
}

.flag-select__btn {
  padding-top: 2px !important;
  padding-right: 475px !important;
  color: #4d4d4d;
  border: none;
  background: transparent;
}

/* pagination search queries */

.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
  /* border: 0.9px solid; */
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: #209cee;
  border-color: #209cee;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
  background-color: #209cee;
  color: white;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  padding: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: none !important;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  margin-right: 0;
  padding: 0px !important;
  border-top-right-radius: 4px;
  display: none !important;
}

.pagination-block {
  text-align: right;
}
.paginationBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 180px;
  max-width: 1344px;
  margin: auto;
  padding: 5px;
}
/* featured account */

.featured-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  border: 0px solid transparent;
}

.card {
  background-color: #edf1f4;
  padding-right: 10px;
  margin-top: 10px;
  padding-left: 10px;
  border: 0px solid transparent;
  box-shadow: 0px 0px 0px !important;
  cursor: pointer;
}

.react-multi-carousel-track {
  background-color: #edf1f4;
  padding-bottom: 15px !important;
}

.first-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.featured-name {
  font-size: 15px;
  padding-right: 10px;
}

@media only screen and (max-width: 380px) {
  .featured-name {
    font-size: 4vw;
  }
}

.featured-flag {
  height: 20px;
  padding-left: 10px;
}

.featured-country-name {
  font-size: 10px;
}

.second-row {
  font-size: 13px;
}

.title {
  text-align: center;
  font-size: 1.5rem !important;
}

.featured-title {
  color: #68a22d;
  font-family: "Patrician";
}

.hr {
  /* margin-top: 20px !important; */
  margin-bottom: 14px !important;
  margin: 5px !important;
}

.mb-4 {
  margin-bottom: -10px !important;
}

.column.is-1,
.column.is-1-tablet {
  flex: none;
  width: 4% !important;
}

.mobile-footer {
  width: 50% !important;
  margin-top: 20px;
}

radio-class {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
}

.react-datepicker__navigation--next {
  right: 10px !important;
}

/* banner text */
.text-transition .text-transition_inner > div {
  white-space: nowrap;
  bottom: 15px;
  text-align: center;
  width: 100%;
}

.search-bar {
  min-width: 300px;
}

.calendar {
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 28px;
  cursor: text;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.card-container {
  position: relative;

  display: flex;
  flex: 1;
  top: 0% !important;
  bottom: 32px !important;
  justify-content: "center";
  align-items: "middle";
}

@media only screen and (min-width: 420px) {
  .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    justify-content: center;
  }
}

.react-multi-carousel-item {
  display: flex;
  justify-content: center;
}

.subtitle.is-4 {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.image-flag {
  /* padding-left: 15px;
  height: 20px;
  width: 50px; */
  padding-left: 7px;
  /* height: 16px; */
  width: 48px;
}

.modal-card {
  height: auto;
}

.text-timeline {
  height: 100px;
}
.text-PostMsg {
  height: 150px;
}

.table td {
  border: 0px;
}

.table tr {
  border-bottom: 1px solid #dbdbdb;
}

.go-button {
  background-color: #65a9d6 !important;
}
.chatPage {
  background-color: rgb(197, 192, 192);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatContainer {
  background-color: white;
  height: 80%;
  width: 90%;
}

.header {
  background-color: #65a9d6;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > h2 {
  font-size: 1.5vmax;
  color: white;
  margin: 2.5vmax;
}

.header img {
  margin: 2.5vmax;
  transition: all 0.3s;
  cursor: pointer;
}
.header > img:hover {
  transform: scale(1.2);
}

.chatBox {
  height: 80%;
  box-sizing: border-box;
}

.inputBox {
  height: 10%;
  box-sizing: border-box;
  display: flex;
}

#chatInput {
  width: 80%;
  border: none;
  padding-left: 5px;
  box-sizing: border-box;
  background-color: white;
  outline: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.2vmax;
  border: 0.2vmax solid rgba(41, 41, 41, 0.562);
}

.sendBtn {
  background-color: #2c689c;
  color: white;
  border: none;
  width: 21%;
  transition: all 0.3s;
  cursor: pointer;
}

.sendBtn > img {
  width: 2.6vmax;
  filter: brightness(100) invert();
  transition: all 0.3s;
}
.sendBtn:hover {
  background-color: rgb(156, 13, 42);
}
.sendBtn:hover img {
  transform: translateX(10px);
}

.lirow {
  display: flex;
  background-color: antiquewhite;
  padding: 10px;
}
.lidivpadding {
  width: 30%;
  font-weight: bold;
  border-right: 2px solid;
}

/* Style the tab */
.tab {
  float: left;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  width: 30%;
  height: 300px;
}

/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: rgb(150, 146, 146);
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: rgb(85, 123, 226);
}

/* Style the tab content */
.tabcontent {
  float: left;
  padding: 0px 12px;
  border: 1px solid #ccc;
  width: 70%;
  border-left: none;
  height: 300px;
}

.footer-custom .column.is-4 {
  width: 80%;
}
.footer-custom .column.is-4 + .column {
  width: 20%;
  justify-content: flex-end;
  display: flex;
  -webkit-justify-content: flex-end;
  display: -webkit-flex;
}
.footer-custom .column.is-4 .columns.is-gapless .column {
  width: auto;
  margin-right: 35px;
}
.footer-custom .column.is-4 + .column .column.is-offset-8 {
  margin: 0;
}
.footer-custom .column.is-4 + .column .column {
  width: auto !important;
  padding: 0 0px;
}

.message-box-borderd {
  border-bottom: 1px solid #65a9d6 !important;
  /* border-radius: 10px !important; */
  margin-bottom: 5px !important;
  padding: 10px;
  padding-left: 10px;
  padding-right: 20px;
}
.msg-box-name-date {
  display: flex;
  justify-content: space-between;
}
.pro-name-post-msg-btn {
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
}
.custom-heart {
  border: none;
  background-color: #fff;
  padding: 5px;

}
.custom-heart span{
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 5px;
  color:'#000'
}

.custom-heart i {
  color: #f20;
}

.image-flag-msg {
  padding-left: 10px;
  height: 15px;
  width: 40px;
}
.width-0-100 {
  margin: 0 100px;
}
@media screen and (max-width: 767px) {
  .width-0-100 {
    margin: 0;
  }
  .custom-modal-card {
    width: 370px !important;
  }
  .table.is-fullwidth td {
    width: 100% !important;
    display: block;
  }
  body table.is_right_viewprofilebtn.is-fullwidth tbody tr td {
    display: table-cell !important;
    width: auto !important;
  }
  body table.is_right_viewprofilebtn.is-fullwidth tbody tr td:nth-child(2) {
    text-align: center;
  }
  body table.is_right_viewprofilebtn.is-fullwidth tbody tr td:last-child {
    text-align: right;
  }
  .custom_footer {
    display: block !important;
  }
  .footer-custom .column.is-4 {
    width: 100% !important;
  }
  .footer-custom .column.is-4 .columns.is-gapless .column {
    width: auto !important;
    margin-right: 14px !important;
  }
  .custom_footer .custom_footer_left .childfooter {
    display: flex !important;
  }
  .custom_footer_right {
    text-align: center;
  }
  .message-box-borderd {
    border: none;
  }
  .border_mobile {
    border-bottom: #2c689c solid;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

.viedoprocress_bar {
  position: relative;
}

.viedoprocress_bar::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url("./images/loading.gif");
  background-size: 60px;
  background-position: center;
  background-repeat: no-repeat;
}
.youtube_video iframe {
  height: 100%;
}

.postMessagebg {
  background-color: #2c689c;
  color: white;
}

.postMessagebg:hover,
.postMessagebg:focus {
  background-color: #2c689c;
  color: white;
}
.image.is-100x100-custom {
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.tab-container {
  /* padding: 0px 18px; */
  padding-left: 0px;
  border-radius: 4px;
}
.tab-menu ul {
  margin: 0;
  padding: 0;
}
.tab-menu ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 15px;
}
.tab-menu ul li {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.4);
  background-color: #b4cbc4;
  padding: 5px 15px;
  border-radius: 4px;
  text-align: center;
}
.tab-menu ul li.active-a {
  background-color: #2c689c;
  color: #ffffff;
}
.tab-menu ul li span {
  margin-right: 0px;
}
.tab {
  display: none;
}
.tab h2 {
  color: rgba(0, 0, 0, 0.7);
}
.tab p {
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
}
.tab-active {
  display: block;
}

.navbar-menu .navbar-item.dropdown button.dropbtn {
  background: transparent;
  color: #363636;
  padding: 0;
}

.navbar-menu .navbar-item.dropdown {
  display: flex;
  align-items: center;
}

.navbar-menu .navbar-item.dropdown .dropdown-content {
  top: 100%;
  right: 0;
}
.ImageRounded {
  padding: 2;
  margin-right: 5;
  border: 0.5px solid #808080;
  border-radius: 50% 50% 0 0;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.circular--portrait {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}
.circular--portrait img {
  width: 100%;
  height: auto;
}

.imgborder {
  border: 0.5px solid #808080;
}
.customTable {
  display: flex;
  border: 1px #666 solid;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
}
.postMessagsImg {
  width: 40px !important;
  height: 40px !important;
  object-fit: cover !important;
  padding: 2px !important;
  margin-right: 5px !important;
  border: 1px solid #808080 !important;
  border-radius: 50% !important;
}

.custom_footer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0px;
}

.childfooter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.threedotsMessage_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.threedotsMessage_btn {
  margin: 5px;
}

.messageDropdown {
  display: block;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
}
button.dropbtn {
  border: none;
}
.dropdown-content > button.dropdown-item {
  background: transparent;
  border-color: transparent;
  cursor: pointer;
  font-size: 1em;
  color: #000;
}
.custom-content {
  background-color: #f1f1f1;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
}
.dropdown-content > button.dropdown-item:hover {
  background-color: #ddd;
}
.dropdown:focus-visible,
.dropdown-trigger:focus-visible {
  outline: none;
}
.is-full-button {
  width: 100%;
}
.dropdown-content.is-gapless {
  padding: 0px;
}
.dropdown-content.is-gapless > div {
  padding: 0px;
}
.dropdown-content.is-gapless > div > button {
  justify-content: flex-start;
}
.custom-modal-card {
  width: 500px;
  height: auto;
}
.content-modal {
  text-align: center;
}
.content-modal-card-head {
  padding: 7px;
}
.content-modal .sizefont {
  font-size: 80px;
  color: #f20;
}
.content-modal p {
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 6px;
}
.content-modal h2 {
  font-size: 16px;
  font-weight: 700;
}
.content-modal .butons-modal > button {
  margin-right: 10px;
}

@media all and (max-width: 1023px) {
  .custom_footer {
    flex-wrap: wrap;
  }
  .custom_footer .custom_footer_left {
    width: 100% !important;
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;
  }
  .custom_footer .custom_footer_right {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }
}

@media all and (max-width: 768px) {
  .custom_footer .custom_footer_left .column.is-4 {
    margin: 5px 0 !important;
    width: 100% !important;
    text-align: center;
    justify-content: center;
  }
  .custom_footer .custom_footer_left .childfooter {
    display: grid;
  }
  .custom-dropdown.dropdown-menu {
    left: auto;
    right: inherit;
  }
  .custom-dropdown {
    display: block;
    width: 100%;
  }
  #dropdown-menu-header {
    position: relative;
    min-width: 100%;
  }
  .aligncenter {
    flex-wrap: wrap !important;
    display: block !important;
    text-align: center !important;
  }
  .pagination-block {
    margin-top: 15px;
    width: 100% !important;
  }
}

.aligncenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.circleImg {
  width: 180px;
  height: 180px;
  margin-right: 5px !important;
  border: 1px solid #808080 !important;
  border-radius: 50% !important;
}

.circleImg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  height: 100%;
  width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.fllower-text {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}
.Click-Follower {
  cursor: pointer;
  text-align: center;
}

.user-profile-hd {
  display: flex;
  align-items: "flex-start";
  justify-content: space-between;
}
.user-profile-image {
  display: flex;
  align-items: center;
}
.user-profile-image .user-avatar {
  margin-right: 20px;
  margin-right: 50px;
}
.user-profile-image .user-avatar {
  flex-shrink: 0;
}
.post-list .card {
  background-color: #fff;
  border-radius: 5px;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px !important;
}

.following-list {
  max-height: calc(50vh - 30px);
  overflow-y: auto;
}

.overfow-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}

@media only screen and (max-width: 767px) {
  .user-profile-hd {
    flex-direction: column;
  }
}

.editbtn {
  border-radius: 10px;
}

.signupin {
  color: blue;
  font-weight: bold;
}

.modal.is-active {
  display: flex;
  padding: 20px;
}
.modal-card,
.modal-content {
  margin: 0 0px;
}
.editForm {
  width: 100%;
}
.has-text-black {
  color: #0a0a0a !important;
  word-break: break-all;
  padding-right: 20px;
}
.wordwapUserName {
  color: #0a0a0a !important;
  word-break: break-all;
  padding-right: 20px;
}
.user-follow-count {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 0px;
}

@media all and (max-width: 767px) {
  .newedit {
    width: 100%;
  }
}


